<template>
  <n-layout-sider :width="220" :native-scrollbar="false" collapse-mode="width" bordered :collapsed="sidebarCollapsed"
    @update:collapsed="appStore.toggleSidebarCollapsed">

    <n-layout-header bordered class='sider-header'>
      <NuxtLink to="/">
        <n-a class="logo">
          <svg viewBox="0 0 472 450">
            <defs>
              <mask id="mask" fill="#fff">
                <path d="M472 114.26L203.029 335.74H407.1L472 449.48H64.9L0 335.74l268.971-221.48H64.9L0 .52h407.1z" />
              </mask>
              <filter id="shadow" x="-12.7%" y="-13.4%" width="125.4%" height="126.7%" filterUnits="objectBoundingBox">
                <feOffset in="SourceAlpha" result="offset-outer" />
                <feGaussianBlur stdDeviation="20" in="offset-outer" result="blue-outer" />
                <feComposite in="blue-outer" in2="SourceAlpha" operator="out" result="blue-outer" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" in="blue-outer" />
              </filter>
            </defs>
            <g mask="url(#mask)">
              <path fill="currentColor" d="M0 0h472v449H0z" />
              <path d="M0 335.74l64.9 113.74L472 114.26 407.1.52z" filter="url(#shadow)" />
            </g>
          </svg>
          <span> Robogo </span>
        </n-a>
      </NuxtLink>

      <n-space align="center" justify="space-between" class="mt-4">
        <n-space align="center">
          <n-avatar circle>{{ workspace?.name[0] }}</n-avatar>
          <n-text type="primary" class='font-bold'>{{ workspace?.name }}</n-text>
        </n-space>
        <n-button quaternary size="tiny" @click="openModal">
          <template #icon>
            <n-icon :component="AppSwitcher"></n-icon>
          </template>
        </n-button>
      </n-space>

    </n-layout-header>

    <n-menu :value="selectedMenu" :options="menuOptions" :root-indent="28" @update:value="onItemClick" />

    <div class="h-[60px]"></div>

    <n-layout-footer bordered class="sider-footer">
      <n-space justify="end" align="center">

        <n-button quaternary ghost circle @click="appStore.toggleSidebarCollapsed">
          <template #icon>
            <n-icon :component="sidebarCollapsed ? DoubleRightOutlined : DoubleLeftOutlined"></n-icon>
          </template>
        </n-button>
      </n-space>
    </n-layout-footer>

    <ModalFormV2 full-title="Switch Workspace" v-model:modal="showModal" v-model:form="formModel" @show="onShow"
      :submit-fn="() => onSelectWorkspace(formModel.workspaceId as string)" confirm-text="Switch"
      :confirm-loading="workspaceLoading">
      <n-list hoverable clickable bordered>
        <template v-for='workspace in workspaces' :key='workspace._id'>
          <n-list-item @click="onWorkspaceItemClick(workspace.workspaceId)">
            <template #prefix>
              <n-avatar class='bg-red-400'>
                {{ workspace.name[0] }}
              </n-avatar>
            </template>

            <n-text :type="activeWorkspace(workspace.workspaceId) ? 'primary' : 'default'" class='font-bold'>
              {{ workspace.name }}
            </n-text>

            <template v-if="activeWorkspace(workspace.workspaceId)" #suffix>
              <n-icon :component="Checkmark" size="28" class="text-green-600" />
            </template>
          </n-list-item>
        </template>
      </n-list>
    </ModalFormV2>
  </n-layout-sider>
</template>

<script lang='ts' setup>
import { storeToRefs } from 'pinia';
import { Carbon3DMprToggle, ShoppingCartMinus, Development, Settings, UserProfile, Wikis, AppSwitcher, Checkmark } from '@vicons/carbon';
import { NIcon, useThemeVars } from 'naive-ui';
import { useUserStore, useAppStore } from '~~/stores';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@vicons/antd'

const appStore = useAppStore();
const { sidebarCollapsed } = storeToRefs(appStore)
const userStore = useUserStore();
const { workspace } = storeToRefs(userStore)

const route = useRoute();
const router = useRouter();
const { locale } = useI18n();
const selectedMenu = computed(() => {
  if (!route.name) {
    return ''
  }

  const names = route.name.toString().split('-')
  if (names.length > 0) {
    let name = names[0]
    if (!name.includes('___')) {
      name += `___${locale.value}`
    }
    return name
  }

  return '';
});

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

const menuOptions = computed(() => {
  return [
    {
      label: 'Projects',
      key: `projects___${locale.value}`,
      icon: renderIcon(Development),
    },
    {
      label: 'Resource',
      key: `resource___${locale.value}`,
      icon: renderIcon(Carbon3DMprToggle),
    },
    {
      label: 'Proxy',
      key: `proxy___${locale.value}`,
      icon: renderIcon(Wikis),
    },
    {
      label: 'Profile',
      key: `profile___${locale.value}`,
      icon: renderIcon(UserProfile),
    },
    {
      label: 'Checkout',
      key: `checkout___${locale.value}`,
      icon: renderIcon(ShoppingCartMinus),
    },
    {
      label: 'Settings',
      key: `settings___${locale.value}`,
      icon: renderIcon(Settings),
    }
  ];
});

function onItemClick(item: string) {
  router.replace({ name: item });
}

const formModel = ref({
  workspaceId: workspace.value?.workspaceId,
})

const { workspaces, onLoadWorkspaceAll, onSelectWorkspace, workspaceLoading } = useUserWorkspace();
const { showModal, openModal } = useModal();

async function onShow() {
  await onLoadWorkspaceAll();
  formModel.value.workspaceId = workspace.value?.workspaceId;
}

function activeWorkspace(workspaceId: string) {
  return workspaceId === formModel.value.workspaceId;
}

function onWorkspaceItemClick(workspaceId: string) {
  formModel.value.workspaceId = workspaceId;
}

</script>

<style lang='scss' scoped>
$bezier: cubic-bezier(0.4, 0, 0.2, 1);

.sider-header {
  max-width: 219px;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 12px 20px;
  background: var(--n-color);
}

.sider-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 12px;
  background: var(--n-color);
  height: 60px;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: padding 0.3s $bezier, font-size 0.3s $bezier;
}

.n-layout-sider--collapsed .sider-header {
  max-width: 47px;
  padding: 12px 8px;
  font-size: 0;
}

.logo svg {
  flex: 0 0 32px;
  height: 32px;
  margin-right: 12px;
  transition: margin 0.3s var(--bezier);
}

.n-layout-sider--collapsed .logo svg {
  margin-right: 0;
}
</style>
