<template>
  <n-layout has-sider class='layout'>
    <Menu/>
    <n-layout-content>
      <NuxtPage />
    </n-layout-content>
  </n-layout>
</template>

<script setup lang='ts'>
import { NDivider } from 'naive-ui';

const colorMode = useColorMode();
console.log(colorMode.preference);
</script>

<style lang='scss' scoped>
.layout {
  height: 100vh;
}
</style>
